


function NoPage() {
  return (
    <>
      <div className='content-wrapper '>
        <h1>Error! Unknown page</h1>     
        <div className='row-box'>
        
        <div className='col-2'>
          </div>
        
          <div className='col-2'>
          </div>
        </div>
        <div className='spacer'></div>
      </div>
  </>
  );
}

export default NoPage;
