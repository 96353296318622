

import { Outlet, Link } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';

function Home() {
  return (
    <div className="wrapper">
        <Header />
        <Outlet/>
        <Footer/>
  </div>
  );
}

export default Home;
