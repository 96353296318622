
import pic_0  from '../../img//slide_0.jpg';
import pic_1  from '../../img//slide_1.jpg';
import video from '../../media/employu-explainer_2.mp4'

function About() {
  return (
    <>
     <div className='content-wrapper'>
          <h1>About</h1>
          <div className='row-box'>
          
          <div className='col-2'>
          <h2>About employU</h2>
          
          <p>employU is a platform that connects employees and employers primally on skilled, semi-skilled and unskilled labour across South Africa.</p>
          <p>
South Africa’s unemployment rate reached 32.6% in the first three months of 2021 and SA’s smartphone penetration reached 91.2% in 2019. These 2 factors have motivated and inspired Andrew Bishop (Founder of hintotex.co.za) to create a progressive web application to make employee seeking and job hunting simple and convenient, on demand basis.</p>
<p>Together with employU and their partners we plan to develop the members of the platform to make them more attractive to potential employers who are registered on the platform.</p>

            <p align='right'> </p>
            </div>
          
            <div className='col-2'>
            <video width="100%" height="320" controls>
            <source src={video} type="video/mp4" />
</video>    <p><span className='bold-text'>Watch employU</span> summary video clip.</p>
              
            <p align='right'> </p>
           
            </div>
          </div><div className='image-art' style={{backgroundImage: `url(${pic_0})` , backgroundSize : 'cover'}}></div>
          <div className='row-box'>
          
          <div className='col-2'>
          <h2>Employees</h2>
          
          <p>Each employee will be screened; a simple test will be conducted to establish level of competence for each employee</p>
          <p>Each employee user will be required to complete a standard CV which will be populated by the employee.</p>
          <p>An employee user can apply for premium membership which conducts and a criminal check as well as connects them with potential employment as a priority candidate.</p>

            <p align='right'> </p>
            </div>
          
            <div className='col-2'>
            <h2>Employers</h2>
          
          <p>employU platform allows the employers to rate the employees or labour that they used out of 5 stars to create Super Employees on the platform in order for the employees to have positive references.</p>
            <p align='right'> </p>
           
            </div>
          </div><div className='image-art' style={{backgroundImage: `url(${pic_1})` , backgroundSize : 'cover'}}></div>
          <div className='row-box'>
          
          <div className='col-2'>
          <h2>Gogo Nandi’s incentive</h2>
          
          <p>EmployU will use 20% of all revenue to finance one person out of the platform in a GogoNandis www.gogonandis.co.za franchise in order for them to persue their entrepreneurial dreams and further develop the Semi Skilled and Unskilled labour to create a better future for the population who are at this level</p>
            <p align='right'> </p>
            </div>
          
            <div className='col-2'>
            <h2> Premium membership</h2>
          
          <p>Each employee member on the site will pay subscription of R10/month or R99/year, premium membership R20/month R199/year incudes criminal check and priority posting. 20% of all revenue goes into to financing a member of the platform into a gogonandis business of their own.</p>
        
          <h2>Andrew Bishop</h2>
              <p>Andrew Bishop founder of Hintotex Brands, Gogo Nandi’s, Bishop Capital and several other businesses has been an entrepreneur since the early 2000’s inspired by his father Bruce Bishop. With businesses that employ over 320 people Mr Bishop understands the challenge of finding the right talent for the job.</p>
              <p>Andrew has now created an app to combat unemployment and established a convenient system that enable skilled and unskilled people to create an online work profile that is accessible by employers nationwide</p>

            <p align='right'> </p>
           
            </div>
          </div> <div className='spacer'></div>
        </div>
  </>
  );
}

export default About;
