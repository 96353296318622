

import Slider from './Slider';
import video from '../../media/employu-explainer_2.mp4'

function Home() {
  return (
    <>
      <Slider />
      <div className='content-wrapper'>
          
        <div className='row-box'>
        
        <div className='col-2'>
        
                    <p><span className='bold-text'>employU</span> is a platform that connects employees and employers primally on skilled, semi-skilled and unskilled labour across South Africa.</p>
                    <p><span className='bold-text'>employU</span> enables workers to create a public and private profile accessible by employers nationwide. Whether you are a painter, shop keeper or cleaner this web app helps you find a part-time or permanent job.</p>
          <p align='right'><a href='/about' className='home-btn'>Read more</a> </p>

          </div>
        
          <div className='col-2'> 
          
       <p></p>
            <video width="100%" height="320" controls>
            <source src={video} type="video/mp4" />
</video>    <p><span className='bold-text'>Watch employU</span> summary video clip.</p>
            <p></p>
          </div>
        </div>
        <div className='spacer'></div>
      </div>
  </>
  );
}

export default Home;
