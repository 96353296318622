


function Contact() {
  return (
    <>
      <div className='content-wrapper '>
        <h1>Contact us</h1>     
        <div className='row-box'>
        
        <div className='col-2'>
          <p>
            <div className='contact-info'>
            <div className="contact-row"><div className="label">Tel:</div><div className="details">info@employu.co.za<br/>

</div> </div>
            <div className="contact-row"><div className="label">Email:</div><div className="details">+27 33 346 0089/99</div> </div>
            <div className="contact-row"><div className="label">Address:</div><div className="details">
            
www.employu.co.za<br/><br/>
</div> </div></div>
</p>
         
          </div>
        
          <div className='col-2'>
          <form className="public-form">
            
          <div className="form-row"><label>Name</label><input type='text' placeholder='Name'/></div>
          <div className="form-row"><label>Email</label><input type='text' placeholder='Email'/></div>
          <div className="form-row"><label>Message</label><textarea type='text' placeholder='Message'></textarea></div>
          <div className="form-row"><label>Anti-spam</label> 4 + 7 =  <input type='text' placeholder='Answer' style={{'width': '70px'}}/></div>
          <div className="form-row-1" align='right'><button>Send message</button></div>
            
            </form>

          </div>
        </div>
        <div className='spacer'></div>
      </div>
  </>
  );
}

export default Contact;
