


function About() {
  return (
    <>
      <div className='content-wrapper '>
          <form className="public-form">
          <h1>Login</h1>     
        <div className="form-row"><label>Email</label><input type='text' placeholder='Email'/></div>
        <div className="form-row"><label>Password</label><input type='password' placeholder='Password'/></div>
        <div className="form-row-1" align='right'><button>Login</button></div>
              
              </form>
  
        <div className='spacer'></div>
      </div>
  </>
  );
}

export default About;
