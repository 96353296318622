


function Footer() {
  return (
      <div className='footer'>
      <div className='content-wrapper '>
        <div className='footer-row-box'>
        
        <div className='col-3' style={{textAlign: 'right'}}>
          <h4>Site Map</h4>
          <ul>
          <li><a href='/'>Home</a></li>
          <li><a href='/about'>About us</a></li>
          <li><a href='/contact'>Contact us</a></li>
        
          </ul>
          </div>
        
          <div className='col-3'  style={{textAlign: 'center'}}>
          <h4>Copywrite</h4>
          © employU 2023
        
          </div>
          <div className='col-3'>
          <h4>Co. Business Card</h4>
          info@employu.co.za<br/>
+27 33 346 0089/99<br/>
www.employu.co.za<br/>
          </div>
      
          </div>
          
    </div>
      
    </div>

  );
}

export default Footer;
