
import pic_2  from '../../img/slide_2.jpg';
import pic_3  from '../../img/slide_3.jpg';


function Services() {
  return (
    <>
      <div className='content-wrapper '>
        <h1>Services</h1> 
        <div className='image-art' style={{backgroundImage: `url(${pic_2})` , backgroundSize: 'cover'}}></div>    
            
        <div className='row-box'>
        
        <div className='col-2'>
          <h4>MIOLO DESIGN SHOP</h4>
          <p> 
          <ul><li>Design</li>
          <li>Brand Architecture</li>
          <li>Digital Marketing</li>
          <li>Print Production</li></ul>

          </p><p>
Miolo design studios forms part of
MIOLO Groups graphic design and
brand ‘’birthing’’ division. With a
decade worth of experience, MDS
has birthed a plethora of brands.
We have, time and time again,
ignited what we have call the
WOah factor, a sentiment that
we frequently receive from our
clients. Our designs don’t just
look great, they COMMUNICATE,
INSPIRE and RESIDE.</p>
          </div>
        
          <div className='col-2'>
          <h4>MIOLO EXPERIANCES</h4>
          <p>
          <ul><li>Public Relations</li>
          <li>Event Management</li>
          <li>Exhibitions Management</li>
          <li>Radio Production</li>
          <li>TV Production</li></ul>
          </p>
<p>
After we design and give life to your
brand, we are also highly versed in
‘’activating’ and ‘’experiencing’’ your
brand. Miolo Experiences forms part
of our event and activations division.
We at Miolo believe that one does not
merely attend an event but rather
become immersed in the experience.
This thinking spears our team to
conceptualize and execute client
experiences that COMMUNICATE,
INPIRE and RESIDE with their audiences</p>

          </div>
        </div>
        <div className='image-art' style={{backgroundImage: `url(${pic_3})` , backgroundSize: 'cover'}}></div>    
        
        <div className='spacer'></div>
      </div>
  </>
  );
}

export default Services;
