
import react    from "react";
import Dropdown from './Dropdown';

class Header extends react.Component {
  
  constructor(props) {
      
    super(props);

    this.state = {
      
        popup : false,
        menu: false
        
      };
      
      }
 
  showmenu = () => {
    
    this.setState({ menu : !this.state.menu , popup : !this.state.popup  });

}

render(){

 

 return (
<>
<div className={ this.state.popup ? 'side-menu-show' : 'side-menu' }  onClick={this.showmenu}>
    <div className={ this.state.menu ? 'side-menu-content-show' : 'side-menu-content' } >
          <div className="side-menu-controlheader">
    
      <div className='brand-box'>MENU</div>
        
        <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/about">About</a></li>
            <li><a href="/contact">Contact us</a></li>
            <li> PROFILE ACCESS</li>
            <li><a href="/register">Register</a></li>
            <li><a href="/login">Login</a></li>
            <li><a href="/password-reminder">Password recover</a></li>
       
        </ul>
          </div>
          </div></div>
    <div className='header'>
    <div className='header-wrapper '>
      <a className='logo' href='/'></a>
      <div className='menu'>
        <a href='/'>home</a>
        <a href='/about'>about</a>
        <a href='/contact'>contact us</a>
        <Dropdown/>
     
</div>
<div className='mobile-menu'><a onClick={this.showmenu}>&#9776;</a></div>


</div>
      </div>
      </>);
}
}

export default Header;
