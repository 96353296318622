


function About() {
  return (
    <>
      <div className='content-wrapper '>
        <h1>Partnership</h1>     
        <div className='row-box'>
        
        <div className='col-2'>
        <h4>References</h4>
          <p><ul><li>1.	Seotlong Agricultural School - Mr Maseru Mphati (Mphati & Associates) – 082 654 5301 </li>
          <li>2.	Thiboloha Special School – Mrs Angie Mashinini – (Department of Public Works) – 058 713 0145</li>
          <li>3.	RDP Houses- Mr Kodi Motaung (Department of Housing) – 071 6023 930 / 051 405 4420</li>
          <li>4.	Tennis Court (Dihlabeng Municipality) – Mr Peter Reid – 058 303 5732</li>
          <li>5.	Miri P School and Makgabane S School – Mr Chris Thlahadi (Mphati & Associates) – 058 303 4197</li>
          <li>6.	Quinton Olivier – 076 5106 766 – Gauteng</li>
          <li>7.	Helen Joseph DID Gauteng – 011 861 5067
</li></ul></p>  </div>
        
          <div className='col-2'>
          <h4>Membership</h4>
          <p><ul><li>SA WIC (South African Women in Construction)</li>
          <li>WOESA (Women in Oil & Energy South Africa)</li>
          <li>HOPC (House Of Professional Council)</li>
          <li>PBF – ANC (Progressive Business Forum)</li>
          <li>YWBN – Young Women Business Network</li>
          <li>BMF – Black Management Forum</li>
          <li>NFCOC – NAFCO – Nafcoc Contruction </li></ul>
</p>
          </div>
        </div>
        <div className='spacer'></div>
      </div>
  </>
  );
}

export default About;
