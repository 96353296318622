
function Dropdown() {
  return (
    <div class="dropdown">
    <button class="dropbtn">Sign in</button>
    <div class="dropdown-content">
      <a href="/register"><i class="fas fa-video"></i> Register</a>
      <a href="/login"><i class="fas fa-book-open"></i> Login</a>
      <a href="/password-reminder"><i class="fas fa-gamepad"></i> Password recover</a>
    </div>
  </div>

  );
}

export default Dropdown;
