


import sasseta  from '../../img/work/sasseta.jpg';
import sasseta_demo  from '../../img/work/sasseta_demo.jpg';
import pamodzi  from '../../img/work/pamodzi.jpg';
import darklovey  from '../../img/work/darklovey.jpg';
import fleetafrica  from '../../img/work/fleetafrica.jpg';
import React from 'react';


class Clients extends React.Component {

  popup = (img) => {

        alert(img)
  
      }

 render(){ return (
    <>
      <div className='content-wrapper '>
        <h1>Clients</h1> 
        <div className='row-box'>
        
        <div className='col-2'>
          <h3>Clients & examples 1</h3>

          <a className='item' onClick={e => this.popup(sasseta_demo)}><div className='item-footer'><h4>Sesseta stationery</h4><div>Stationery design</div></div></a>
          <div className='item' style={{backgroundImage: `url(${darklovey})`}}><div className='item-footer'><h4>Dark & Lovely</h4></div></div>
          <div className='item' style={{backgroundImage: `url(${pamodzi})`}}><div className='item-footer'><h4>Pamodzi</h4></div></div>
          <div className='item' style={{backgroundImage: `url(${fleetafrica})`}}><div className='item-footer'><h4>Fleet Africa</h4></div></div>
          <div className='item' style={{backgroundImage: `url(${pamodzi})`}}><div className='item-footer'><h4>Sesseta stationery</h4></div></div>

</div>
        
          <div className='col-2'>
          <h3>Clients & examples 2</h3>

          <div className='item'><div className='item-footer'><h4>Dark & Lovely</h4></div></div>
          <div className='item'><div className='item-footer'><h4>Dark & Lovely</h4></div></div>
          <div className='item'><div className='item-footer'><h4>Dark & Lovely</h4></div></div>
          <div className='item'><div className='item-footer'><h4>Dark & Lovely</h4></div></div>
          <div className='item'><div className='item-footer'><h4>Dark & Lovely</h4></div></div>

          </div>
        </div>
        <div className='spacer'></div>
      </div>
  </>
  );
}
}
export default Clients;
