


function Passowrd() {
  return (
    <>
      <div className='content-wrapper '>
          <form className="public-form"> <h1>Password recovery</h1>
            
            <div className="form-row"><label>Email</label><input type='text' placeholder='Email'/></div>
            <div className="form-row-1" align='right'><button>Reset password</button></div>
              
              </form>
  
        <div className='spacer'></div>
      </div>
  </>
  );
}

export default Passowrd;
